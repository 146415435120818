import { Component, Mixins } from 'vue-property-decorator';
import template from './AjoutAccordCadre.Template.vue';
import ContainerAccordsCadresForm from '@/components/AccordsCadres/ContainerAccordsCadresForm.vue';
import { Mutation } from 'vuex-class';
import { UtilisateurStoreMethods } from '@/store/modules/utilisateur/UtilisateurStore';
import { CommonMixin } from '@/shared/mixins';
import { AccordsCadresStoreMethods } from '@/store/modules/accordscadres/AccordsCadresStore';
import { PrescripteurInterne, Profil } from '@/models';
import { ApiAutocompleteHelper } from '@/services/ApiAutocompleteHelper';
import ApiHelper from '../../../services/ApiHelper';
import { EnumProfilsAsNumber } from '@/shared/enums';

@Component({
    ...template,
    name: 'AjoutAccordCadre',
    components: {ContainerAccordsCadresForm},
})
export default class AjoutAccordCadre extends Mixins(CommonMixin) {

    private prescripteursInterne: PrescripteurInterne[] = new Array<PrescripteurInterne>();

    @Mutation(UtilisateurStoreMethods.SET_PROFILS)
    private setProfils: (profils: Profil[]) => void;

    @Mutation(AccordsCadresStoreMethods.RESET_ALL)
    public resetAll: () => void;

    public created() {
        this.resetAll();
    }
    public mounted() {
        ApiHelper.getAll<Profil>('profil/obtenirTous').then((reponse) => {
            this.setProfils(reponse.filter((p) => p.isIntern === false && p.id !== EnumProfilsAsNumber.ApporteurAffaires));
        });
    }
    /**
     * Promesse pour l'AutoComplete des prescripteurs internes.
     * @param nom Critère de recherche.
     */
    public prescripteursInternesPromise(recherche: string): Promise<PrescripteurInterne[]> {
        return ApiAutocompleteHelper.getAutocompletePromise<PrescripteurInterne>(recherche, `/prescripteurInterne/obtenirTous`);
    }
    /**
     * Promesse pour l'AutoComplete d'un prescripteur interne.
     * @param nom Critère de recherche.
     */
    public prescripteursInternesUpdatePromise(id: number): Promise<PrescripteurInterne> {
        return ApiAutocompleteHelper.getAutocompletebyIdPromise<PrescripteurInterne>(id, `/prescripteurInterne/obtenir`);
    }

}
