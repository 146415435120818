



































import { Component, Vue, Prop } from 'vue-property-decorator';
import VueRouter from 'vue-router';
import { AccordCadre, Profil, PrescripteurInterne } from '@/models';
import AccordsCadresGeneralForm from '@/components/AccordsCadres/AccordsCadresGeneralForm.vue';
import AccordsCadresSocieteForm from '@/components/AccordsCadres/AccordsCadresSocieteForm.vue';
import AccordsCadresRoleForm from '@/components/AccordsCadres/AccordsCadresRoleForm.vue';
import { Getter, Action } from 'vuex-class';
import { AccordsCadresStoreMethods } from '@/store/modules/accordscadres/AccordsCadresStore';
import { UtilisateurStoreMethods } from '@/store/modules/utilisateur/UtilisateurStore';
import { AuthStoreMethods } from '@/store/modules/auth/AuthStore';
import { UserProfile } from '@/store/modules/auth/types';

@Component({
    name: 'ContainerAccordsCadresForm',
    components: { AccordsCadresGeneralForm, AccordsCadresSocieteForm, AccordsCadresRoleForm },
})
export default class ContainerAccordsCadresForm extends Vue {
    public $refs!: {
        form: HTMLFormElement;
    };
    // Liste des Prescripteur Interne agissant comme responsable de l'accord-cadre récupéré depuis le composant parent
    @Prop()
    public prescripteursInternesPromise!: (recherche: string) => Promise<PrescripteurInterne[]>;

    // Prescripteur Interne agissant comme responsable de l'accord-cadre récupéré depuis le composant parent
    @Prop()
    public prescripteursInternesUpdatePromise!: (id: number) => Promise<PrescripteurInterne>;

    // Promesse pour récupérer le profil de l'utilisateur.
    @Getter(AuthStoreMethods.USER_PROFILE)
    public getUserProfile!: Promise<UserProfile>;

    // Profils.
    @Getter(UtilisateurStoreMethods.PROFILS)
    private profils!: Profil[];

    @Action(AccordsCadresStoreMethods.PERSISTER_ACCORD_CADRE)
    public persisterAccordCadre!: () => Promise<AccordCadre>;

    @Action(AccordsCadresStoreMethods.SET_DROITS_ACTION)
    public definirDroitsAccordcadre: () => void;

    private loading = false;

    public submit(): void {
        if (this.$refs.form.validate()) {
            this.loading = true;
            this.persisterAccordCadre().then(thisaccordCadre => {
                if (thisaccordCadre) { (this.$router as VueRouter).push({ name: 'edit-accord-cadre', params:{id : `${thisaccordCadre.id}`}} ); }
            }).finally(() => {this.loading = false;});
        }
    }
    /**
     * Retour à la page d'administration.
     */
    public annulationEdition(): void {
        (this.$router as VueRouter).push({ name: 'accords-cadres' });
    }
}
